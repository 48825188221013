<template>
  <!-- begin:: Header -->
  <div id="kt_header_guest" ref="kt_header_guest" class="header" v-bind:class="headerClasses">
    <div
      class="d-flex align-items-center justify-content-between"
      v-bind:class="{
        'container-fluid': widthFluid,
        container: !widthFluid,
      }"
    >
      <div class="d-flex align-items-stretch mr-3">
        <div class="header-logo">
          <router-link to="/">
            <img alt="Logo" :src="headerBg" class="logo-default rounded max-h-40px" />
            <img alt="Logo" :src="headerBgSticky" class="logo-sticky rounded max-h-40px" />
          </router-link>
        </div>
        <!-- begin:: Header Menu -->
        <div class="header-menu-wrapper header-menu-wrapper-left" ref="kt_header_guest_menu_wrapper">
          <div
            v-if="headerMenuEnabled"
            id="kt_header_guest_menu"
            ref="kt_header_guest_menu"
            class="header-menu header-menu-mobile"
            v-bind:class="headerMenuClasses"
          >
            <!-- example static menu here -->
            <KTMenuGuest></KTMenuGuest>
          </div>
        </div>
        <!-- end:: Header Menu -->
      </div>

      <KTTopbarGuest></KTTopbarGuest>
    </div>
  </div>
  <!-- end:: Header -->
</template>

<script>
import { mapGetters } from "vuex";
import KTLayoutHeader from "@/assets/js/layout/base/header.js";
import KTLayoutHeaderMenu from "@/assets/js/layout/base/header-menu.js";
import KTMenuGuest from "@/view/layout/header-guest/MenuGuest.vue";
import KTTopbarGuest from "@/view/layout/header-guest/TopbarGuest";

export default {
  name: "KTHeaderGuest",
  components: {
    KTTopbarGuest,
    KTMenuGuest,
  },
  mounted() {
    // Init Desktop & Mobile Headers
    KTLayoutHeader.init("kt_header_guest", "kt_header_mobile_guest");

    // Init Header Menu
    KTLayoutHeaderMenu.init(this.$refs["kt_header_guest_menu"], this.$refs["kt_header_guest_menu_wrapper"]);
  },
  computed: {
    ...mapGetters(["layoutConfig", "getClasses", "resolveWhiteLabelLogo", "resolveWhiteLabelLogoSticky"]),

    headerBg() {
      return this.resolveWhiteLabelLogo;
      // return process.env.BASE_URL + this.layoutConfig("self.logo.default");
    },
    headerBgSticky() {
      return this.resolveWhiteLabelLogoSticky;
      // return process.env.BASE_URL + this.layoutConfig("self.logo.sticky");
    },
    /**
     * Check if the header menu is enabled
     * @returns {boolean}
     */
    headerMenuEnabled() {
      return !!this.layoutConfig("header.menu.self.display");
    },

    /**
     * Get extra classes for header based on the options
     * @returns {null|*}
     */
    headerClasses() {
      const classes = this.getClasses("header");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Get extra classes for header menu based on the options
     * @returns {null|*}
     */
    headerMenuClasses() {
      const classes = this.getClasses("header_menu");
      if (typeof classes !== "undefined") {
        return classes.join(" ");
      }
      return null;
    },

    /**
     * Check if header container is fluid
     */
    widthFluid() {
      return this.layoutConfig("header.self.width") === "fluid";
    },
  },
};
</script>
