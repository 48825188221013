<template>
  <ul class="menu-nav">
    <router-link :to="{ name: 'bundle-list' }" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ $tc("GENERAL.CONTENT", 2) | uppercase }} </span>
        </a>
      </li>
    </router-link>

    <router-link :to="{ name: 'general-redeem' }" v-slot="{ href, navigate, isActive, isExactActive }">
      <li aria-haspopup="true" data-menu-toggle="hover" class="menu-item" :class="[isActive && 'menu-item-active', isExactActive && 'menu-item-active']">
        <a :href="href" class="menu-link" @click="navigate">
          <span class="menu-text"> {{ $t("GENERAL.REDEEM_TOKEN") | uppercase }} </span>
        </a>
      </li>
    </router-link>
  </ul>
</template>

<script>
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "KTMenuGuest",
  components: {},
  mixins: [uppercase],
  methods: {
    hasActiveChildren(match) {
      return this.$route["path"].indexOf(match) !== -1;
    },
  },
};
</script>
