<template>
  <!-- begin:: Footer -->
  <div class="footer kt-grid__item bgi-position-center-center bgi-size-cover bgi-no-cover" id="kt_footer" style="background: #233f5d">
    <!--begin::Container-->
    <div class="container py-lg-18 py-8">
      <!--begin::Row-->
      <div class="row">
        <!--begin::Col-->
        <div class="col-lg-4 my-lg-0 my-5">
          <h4 class="text-white pb-3">{{ $t("GENERAL.ABOUT") }}</h4>
          <p class="m-0 text-white opacity-45">
            {{ $t("GENERAL.ABOUT_FOOTER") }}
          </p>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-4 my-lg-0 my-5">
          <h4 class="text-white pb-3">
            {{ $t("GENERAL.QUICK_LINKS") }}
          </h4>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column mr-18">
              <router-link :to="{ name: 'auth-signin' }" class="text-white opacity-55 text-hover-primary">
                {{ $t("GENERAL.SIGNIN") }}
              </router-link>

              <router-link :to="{ name: 'general-gdpr' }" class="text-white opacity-55 text-hover-primary mt-2">
                {{ $t("GENERAL.GDPR") }}
              </router-link>
            </div>
            <div class="d-flex flex-column">
              <a href="#" class="text-white opacity-55 text-hover-primary"> User Setting </a>
              <a href="#" class="py-2 text-white opacity-55 text-hover-primary"> Custom Pages </a>
              <a href="#" class="text-white opacity-55 text-hover-primary"> Intranet Settings </a>
            </div>
          </div>
        </div>
        <!--end::Col-->
        <!--begin::Col-->
        <div class="col-lg-4 my-lg-0 my-5">
          <h4 class="text-white pb-3">
            {{ $t("GENERAL.NEWSLETTER") }}
          </h4>
          <form class="rounded" style="background-color: rgba(0, 0, 0, 0.2)">
            <div class="input-group p-2 align-items-center">
              <input type="text" class="form-control rounded-right border-0 bg-transparent text-white opacity-80" :placeholder="$t('GENERAL.EMAIL')" />
              <div class="input-group-append p-0 mr-1">
                <button class="btn btn-fh btn-sm btn-primary px-6 rounded-left" type="button">
                  {{ $t("GENERAL.JOIN") }}
                </button>
              </div>
            </div>
          </form>
        </div>
        <!--end::Col-->
      </div>
      <!--end::Row-->
    </div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="separator separator-solid opacity-7"></div>
    <!--end::Container-->
    <!--begin::Container-->
    <div class="container py-8">
      <div class="d-flex align-items-center justify-content-between flex-lg-row flex-column">
        <!--begin::Copyright-->
        <div class="d-flex align-items-center order-lg-1 order-2">
          <img alt="Logo" src="media/logos/life-academy/200.png" class="logo-sticky max-h-35px" />
          <span class="text-muted font-weight-bold mx-2">1989 - {{ currentYear }} {{ layoutConfig("appName") }}</span>

          <span class="text-white">®</span>
        </div>
        <!--end::Copyright-->
        <!--begin::Nav-->
        <div class="d-flex align-items-center order-lg-2 order-1 mb-lg-0 mb-5">
          <router-link :to="{ name: 'general-privacy' }" class="text-white opacity-70 px-6 text-hover-primary">
            {{ $t("GENERAL.PRIVACY") }}
          </router-link>
          <router-link :to="{ name: 'general-contact' }" class="text-white opacity-70 pr-0 text-hover-primary">
            {{ $t("GENERAL.CONTACT") }}
          </router-link>
        </div>
        <!--end::Nav-->
      </div>
    </div>
    <!--end::Container-->
  </div>
  <!-- end:: Footer -->
</template>

<script>
import { mapGetters } from "vuex";

export default {
  name: "KTFooterExtended",
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if footer container is fluid
     */
    widthFluid() {
      return this.layoutConfig("footer.width") === "fluid";
    },
    currentYear() {
      let d = new Date();

      return d.getFullYear();
    },
  },
};
</script>
