<template>
  <div class="subheader py-2 subheader-transparent" v-bind:class="subheaderClasses" id="kt_subheader_guest">
    <div
      class="d-flex align-items-center justify-content-between flex-wrap flex-sm-nowrap"
      v-bind:class="{
        'container-fluid': widthFluid,
        container: !widthFluid,
      }"
    >
      <div class="d-flex align-items-center flex-wrap mr-2">
        <!--begin::Heading-->
        <div class="d-flex flex-column">
          <!--begin::Title-->

          <ul class="d-flex align-items-center font-weight-bold my-2" v-if="breadcrumbs.length > 0">
            <i class="flaticon2-shelter text-white icon-1x"></i>

            <template v-for="(breadcrumb, i) in breadcrumbs">
              <span class="label label-dot label-sm bg-white opacity-75 mx-3" :key="i"></span>
              <router-link
                :key="`${i}-${breadcrumb.id}`"
                v-if="breadcrumb.route"
                :to="{ name: breadcrumb.route }"
                class="text-white text-hover-white opacity-75 hover-opacity-100"
              >
                {{ breadcrumb.title | uppercase }}
              </router-link>
              <span class="text-white text-hover-white opacity-75 hover-opacity-100" :key="`${i}-${breadcrumb.id}`" v-if="!breadcrumb.route">
                {{ breadcrumb.title | uppercase }}
              </span>
            </template>
          </ul>
          <h2 class="text-white font-weight-bold my-8 mr-5">
            {{ title }}
          </h2>
        </div>
      </div>
      <div class="d-flex align-items-center"></div>
    </div>
  </div>
</template>

<style lang="scss">
.custom-v-dropdown {
  &.dropdown-toggle {
    padding: 0;
    &:hover {
      text-decoration: none;
    }

    &.dropdown-toggle-no-caret {
      &:after {
        content: none;
      }
    }
  }

  &.dropdown-menu {
    margin: 0;
    .b-dropdown-text {
      padding: 0;
    }
  }
}
</style>

<script>
import { mapGetters } from "vuex";
import uppercase from "@/core/mixins/uppercase.filter";

export default {
  name: "KTSubheaderGuest",
  props: {
    breadcrumbs: Array,
    title: String,
  },
  mixins: [uppercase],
  computed: {
    ...mapGetters(["layoutConfig"]),

    /**
     * Check if subheader width is fluid
     */
    widthFluid() {
      return this.layoutConfig("subheader.width") === "fluid";
    },

    subheaderClasses() {
      const classes = [];
      const style = this.layoutConfig("subheader.style");
      if (style) {
        classes.push(style);

        if (style === "solid") {
          classes.push("bg-white");
        }

        if (this.layoutConfig("subheader.fixed")) {
          classes.push("border-top");
        }
      }
      return classes.join(" ");
    },
  },
};
</script>
